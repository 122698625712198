import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show"
export default class extends Controller {
  static targets = ['output', 'submit']

  password() {
    if (this.submitTarget.value == 'Show') {
      this.outputTarget.type = 'text'
      this.submitTarget.value = 'Copy'
    } else {
      navigator.clipboard.writeText(this.outputTarget.value).then(() => {
        this.outputTarget.parentElement.innerHTML = '<p class="text-center">Copied to clipboard!</p>';
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  }
}
