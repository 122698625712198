import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link']

  toClipboard() {
    navigator.clipboard.writeText(this.linkTarget.innerHTML).then(() => {
      alert('Copied to clipboard!')
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }
}
